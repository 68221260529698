import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { profilesService } from './../../services/firebase/profiles.service';
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  
  
  profilesSubscription: Subscription;
  profilesCompletedSubscription: Subscription;
  profilesCount:any = '0';
  profilesLabel:string = 'TOTAL PROFILES'
  completedProfilesCount:any = '0';
  completedProfilesLabel:string = 'COMPLETED PROFILES'
  
  constructor(private profilesService: profilesService) {}
  ngOnInit() {
    this.profilesSubscription = this.profilesService.getProfilesCount().subscribe((profilesCount) => {
			this.profilesCount = profilesCount.length;
      this.completedProfilesCount = profilesCount.filter((item:any) => {return item.allQuestionsAnswered ? item : null}).length;
		});
  }
}
