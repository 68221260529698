import { ReactiveFormsModule } from '@angular/forms';
import { NLDRForms2Component } from './Forms/2/2.component';
import { NLDRList4Component } from './WidgetLists/4/4.component';
import { NLDORTypography3Component } from './Typography/3/3.component';
import { NLDRTable2Component } from './WidgetsTables/2/2.component';
import { NLDRGeneral24v1Component } from './WidgetsGeneral/24v1/24v1.component';
import { NLDRGeneral24Component } from './WidgetsGeneral/24/24.component';
import { CommonModule } from '@angular/common';
import { NLDRTable3Component } from './WidgetsTables/3/3.component';
import { NgModule } from '@angular/core'
import { SharedModule } from 'src/app/shared.module'

const COMPONENTS = [
  NLDRTable2Component,
  NLDRTable3Component,
  NLDRGeneral24Component,
  NLDRGeneral24v1Component,
  NLDORTypography3Component,
  NLDRList4Component,
  NLDRForms2Component
]

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class NLDRWidgetsComponentsModule {}
