import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'nldr-widgets-general-24',
  templateUrl: './24.component.html',
})
export class NLDRGeneral24Component implements OnInit {
  @Input() count:any = 0;
  @Input() label:any = "Label";
  constructor() {}
  ngOnInit() {}
}
