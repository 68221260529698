<form nz-form [nzLayout]="'inline'" [formGroup]="validateForm2" (ngSubmit)="submitForm2()">
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input a note!">
      <nz-input-group >
        <input nz-input formControlName="note" placeholder="Add Note" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control>
      <button type="submit" class="btn btn-success mt-1 mb-1">
        Add
      </button>
    </nz-form-control>
  </nz-form-item>
  <i class="align-text-bottom fe fe-check-square text-success ml-2 font-size-24 " *ngIf="showNoteAdded"></i>
</form>