import { Component, OnInit, Input } from '@angular/core'
declare var require: any

@Component({
  selector: 'nldr-widgets-tables-2',
  templateUrl: './2.component.html',
  styleUrls: ['./2.component.scss'],
})
export class NLDRTable2Component implements OnInit {
  @Input() tableData: [] = []
  constructor() {}
  ngOnInit() {}
}
