<div>
  <ul class="list-unstyled mb-0">
    <li class="item" *ngFor="let item of items;">
      <div class="itemHead mb-3">
        <div class="itemPic">
          <img src="{{item.createdByImage}}" alt="{{item.createdByName}}" />
        </div>
        <div class="mr-2">
          <div>{{item.createdByName}}</div>
        </div>
        <div class="text-success ml-auto">{{item.created.toDate() | date}}</div>
      </div>
      <p class="mb-4">
        {{item.note}}
      </p>
    </li>
  </ul>
</div>