import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root',
})
export class noldorUtils {
  constructor(public firebaseDatabase: AngularFirestore) {}

  getCollectionList(collection) {
    return new Promise(res => {
      const results = []
      return this.firebaseDatabase
        .collection(collection)
        .ref.get()
        .then(collectionDocs => {
          collectionDocs.forEach(doc => {
            const result = { ...(doc.data() as {}), id: doc.id }
            results.push(result)
          })
          res(results)
        })
    })
  }

  getFilteredCollectionList(collection, key, value) {
    return new Promise(res => {
      const results = []
      return this.firebaseDatabase
        .collection(collection)
        .ref.where(key, '==', value)
        .get()
        .then(collectionDocs => {
          collectionDocs.forEach(doc => {
            const result = { ...(doc.data() as {}), id: doc.id }
            results.push(result)
          })
          res(results)
        })
    })
  }

  getCollectionCount(collection) {
    return new Promise(res => {
      let results = 0
      return this.firebaseDatabase
        .collection(collection)
        .ref.get()
        .then(collectionDocs => {
          results = collectionDocs.size
          res(results)
        })
    })
  }

  getDocument(documentPath) {
    return new Promise(res => {
      let document
      return this.firebaseDatabase
        .doc(documentPath)
        .ref.get()
        .then(documentData => {
          document = documentData.data()
          res(document)
        })
    })
  }

  public deleteDocument(documentPath: string): Promise<void> {
    return this.firebaseDatabase.doc(documentPath).delete()
  }

  addDocumentToCollection(collectionPath: string, document: object) {
    return this.firebaseDatabase.collection(collectionPath).add(document)
  }

  subscribeToCollectionList(collection) {
    return this.firebaseDatabase.collection(collection).valueChanges({ idField: 'id' })
  }
}
