import { profilesService } from './../../../../services/firebase/profiles.service';
import { Component, OnInit, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'

@Component({
  selector: 'nldor-forms-2',
  templateUrl: './2.component.html',
  styleUrls: ['./2.component.scss'],
})
export class NLDRForms2Component implements OnInit {

  @Input() profileId: [] = []
  showNoteAdded: boolean = false;
  validateForm2: FormGroup
  currentUserDetails: any

  constructor(private fb: FormBuilder, private profilesService: profilesService, private store: Store) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.currentUserDetails = state;
    })
  }

  ngOnInit() {
    this.validateForm2 = this.fb.group({
      note: [null, [Validators.required]]
    })
  }

  submitForm2(): void {
    for (const i in this.validateForm2.controls) {
      if (this.validateForm2.controls.hasOwnProperty(i)) {
        this.validateForm2.controls[i].markAsDirty()
        this.validateForm2.controls[i].updateValueAndValidity()
      }
    }
    this.profilesService.addProfileNote(this.profileId, this.validateForm2.value.note, this.currentUserDetails).then((results) => {
      this.showNoteAdded = true;
    });
  }
    

}
