<div class="table-responsive text-nowrap">
  <nz-tabset class="tabs" [nzSelectedIndex]="0">
    <nz-tab nzTitle="Profiles" [nzForceRender]="true">
      <nz-table #basicTable [nzData]="tableData" [nzShowPagination]="true" class="table mb-4">
        <thead>
        <tr>
          <th class="bg-transparent width-50">Completed</th>
          <th class="bg-transparent"> Name</th>
          <th class="bg-transparent">Details</th>
          <th class="bg-transparent">Created</th>
          <th class="bg-transparent">Version</th>
          <th class="bg-transparent text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td>
            <div>
              <i class="fe fe-check" *ngIf="data.allQuestionsAnswered"></i>
              <i class="fe fe-minus-circle" *ngIf="!data.allQuestionsAnswered"></i>
              <span *ngIf="!data.allQuestionsAnswered">{{data.lastAnswerQ}}</span>
            </div>
          </td>
          <td>
            <div>{{data.name}}</div>
            <div class="text-gray-4">{{data.companyName}}</div>
          </td>
          <td>
            <div>{{data.personalDescription}}</div>
            <div class="text-gray-4">{{data.location}}</div>
          </td>
          <td>
            <div>{{convertDate(data.created)}}</div>
            <div class="text-gray-4">{{convertTime(data.created)}}</div>
          </td>
          <td>
            <div>{{data.version}}</div>
          </td>
          <td class="text-right">
            <a routerLink="/view/{{data.id}}" type="button" class="btn btn-primary mr-2">
              <i class="fe fe-eye"></i>
            </a>
            <!--  <button type="button" class="btn btn-light">
               <i class="fe fe-file-text text-blue"></i>
             </button> -->
          </td>
        </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="Reports">
      <p>
        <button type="button" class="btn btn-light"  (click)="exportProfileCSVEvent()">
          New Export to CSV
        </button> (Profile per line)
      </p>

      <p>
        <button type="button" class="btn btn-light"  (click)="exportAnswersCSVEvent()">
          OLD Export to CSV
        </button> (Answer per line)
      </p>
    </nz-tab>
  </nz-tabset>
</div>
