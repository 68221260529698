import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import firebase from 'firebase/app'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import { Router, ActivatedRoute } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import * as UserActions from 'src/app/store/user/actions'
import { merge } from 'rxjs'
import { AngularFireStorage } from '@angular/fire/storage'

interface User {
  uid: string
  email: string
  displayName: string
  photoURL: string
  emailVerified: boolean
  role: string
  created: number
}

/* export const firebaseConfig = {
  apiKey: 'AIzaSyDfmY9lXM9bqUD1x2fMhQzdDlABQVNbRRs',
  authDomain: 'nldr-vb-test.firebaseapp.com',
  projectId: 'nldr-vb-test',
  storageBucket: 'nldr-vb-test.appspot.com',
  messagingSenderId: '718464718369',
  appId: '1:718464718369:web:58440a9ef8fa41ea75b560',
} */

// export const firebaseConfig = {
//   apiKey: 'AIzaSyCbgpswgvb_CjzOYIlYDvJCC9USvzJH93Q',
//   authDomain: 'matchie-live.firebaseapp.com',
//   projectId: 'matchie-live',
//   storageBucket: 'matchie-live.appspot.com',
//   messagingSenderId: '290289298424',
//   appId: '1:290289298424:web:b88c42c1d4f750dee3c40d',
// }

@Injectable({
  providedIn: 'root',
})
export class firebaseAuthService {
  authProvider: string = ''

  constructor(
    public firebaseAuth: AngularFireAuth,
    public firebaseDatabase: AngularFirestore,
    public router: Router,
    public route: ActivatedRoute,
    private notification: NzNotificationService,
    private store: Store<any>,
    private storage: AngularFireStorage,
  ) {
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.authProvider = state.authProvider
    })
    this.firebaseAuth.authState.subscribe(user => {
      if (this.authProvider === 'jwt') {
        return
      }
      if (user) {
        // get user fields from database and merge them with user auth data
        firebaseDatabase
          .collection('users')
          .doc(user.uid)
          .ref.get()
          .then(doc => {
            if (doc.exists) {
              const userFields: any = doc.data()
              console.log('🚀 ~ file: index.ts ~ line 60 ~ firebaseAuthService ~ userFields', userFields)
              const userData = {
                id: user.uid,
                displayName: userFields.displayName,
                role: 'admin',
                avatar: user.photoURL,
                email: user.email,
                authorized: true,
                loading: false,
              }
              if (this.route.snapshot.queryParams.returnUrl) {
                this.router.navigate([this.route.snapshot.queryParams.returnUrl]) // // redirect to returnUrl
              } else if (this.router.url.includes('/auth')) {
                this.router.navigate(['/']) // redirect to root route on auth pages
              }
              this.store.dispatch(new UserActions.LoadCurrentAccountSuccessful(userData)) // save userData to store
            }
          })
      }
    })
  }

  async login(email: string, password: string) {
    return this.firebaseAuth.signInWithEmailAndPassword(email, password).then(() => {
      return Promise.resolve(true)
    })
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider()
    return this.oAuthLogin(provider)
  }

  async register(email: string, password: string, name: string) {
    return this.firebaseAuth.createUserWithEmailAndPassword(email, password).then(response => {
      if (response.user) {
        const { uid } = response.user
        const userData: any = {
          displayName: name,
          email: email.toLowerCase(),
          uid: uid,
        }
        return this.createUserData(userData, false)
      }
      return Promise.resolve()
    })
  }

  private oAuthLogin(provider) {
    return this.firebaseAuth.signInWithPopup(provider).then((credential: any) => {
      // SET USER FIRSTNAME AND SURNAME
      credential.user.firstname = credential.additionalUserInfo.profile.given_name
      credential.user.surname = credential.additionalUserInfo.profile.family_name

      const userData = {
        displayName: credential.user.firstname + ' ' + credential.user.surname,
        email: credential.user.email.toLowerCase(),
        uid: credential.user.uid,
        photoURL: credential.user.photoURL,
        emailVerified: true,
        role: 'admin',
        created: Date.now(),
        socialLogin: true,
      }

      return this.createUserData(userData, true)
    })
  }

  private createUserData(user: User, socialLogin: boolean) {
    const usersRef = this.firebaseDatabase.collection(`users`).doc(user.uid)
    return usersRef.set(user, { merge: true })
  }

  public requestReport(profileId: string) {
    const profileRef = this.firebaseDatabase.collection('profile').doc(profileId)
    return profileRef.set(
      {
        generateReport: true,
        reportLink: '',
        reportDate: new Date(),
        reportStatus: 'REQUESTED',
      },
      { merge: true },
    )
  }

  public async downloadReport(profileId: string) {
    let isReady = false
    await this.firebaseDatabase
      .collection('profile')
      .doc(profileId)
      .ref.get()
      .then(profile => {
        let item: any = profile.data()
        if (!item.reportStatus || item.reportStatus != 'DONE') {
          isReady = false
        } else {
          const ref = this.storage.ref(`reports/${profileId}.docx`)
          ref.getDownloadURL().subscribe(link => {
            window.open(link, '_blank').focus()
            isReady = true
          })
        }
      })
    return isReady
  }

  public requestFounderReport(profileId: string) {
    const profileRef = this.firebaseDatabase.collection('profile').doc(profileId)
    return profileRef.set(
      {
        generateFounderReport: true,
        reportLinkFounder: '',
        reportDateFounder: new Date(),
        reportStatusFounder: 'REQUESTED',
      },
      { merge: true },
    )
  }

  public async regenerateReports(profileId: string) {
    const profileRef = this.firebaseDatabase.collection('profile').doc(profileId)
    await profileRef.set({ allQuestionsAnswered: false }, { merge: true })
    return profileRef.set({ allQuestionsAnswered: true }, { merge: true })
  }

  public async downloadFounderReport(profileId: string): Promise<boolean> {
    let isReady = false
    let profileRef = this.firebaseDatabase.collection('profile').doc(profileId)
    await this.firebaseDatabase
      .collection('profile')
      .doc(profileId)
      .ref.get()
      .then(profile => {
        let item: any = profile.data()
        if (!item.reportStatusFounder || item.reportStatusFounder != 'DONE') {
          isReady = false
        } else {
          const ref = this.storage.ref(`reports/founder/${profileId}.docx`)
          ref.getDownloadURL().subscribe(link => {
            window.open(link, '_blank').focus()
            isReady = true
          })
        }
      })
    return isReady
  }

  async logout() {
    return this.firebaseAuth.signOut()
  }
}
