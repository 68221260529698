import { Subscription } from 'rxjs'
import { Component, OnInit } from '@angular/core'
import { profilesService } from './../../services/firebase/profiles.service'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ChartType, ChartOptions } from 'chart.js'
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts'
import { firebaseAuthService } from '../../services/firebase'

interface WordCloudElement {
  font: string
  hasText: boolean
  height: number
  padding: number
  rotate: number
  size: number
  style: string
  text: string
  value: number
  weight: string
  width: number
  x: number
  x0: number
  x1: number
  xoff: number
  y: number
  y0: number
  y1: number
  yoff: number
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
})
export class ViewComponent implements OnInit {
  profileId: any
  profile: any
  profileAnswers: any
  subscription: Subscription
  anwseredItems: any
  profileDetails: any
  notes: any
  requestedReport = false
  requestedFounderReport = false
  reportLinkPopulated = false
  allQsAnswered = false
  displayDownloadReportButtonInvestor = false
  displayDownloadReportButtonFounder = false

  public wordCloudData: { text: string; value: number }[] = []
  public wordCloudFontSizeMapper: (el: WordCloudElement) => number = ({ value }) => value * 12
  public wordCloudRotate: () => number = () => {
    const rotation = ~~(Math.random() * 4) * 90
    if (rotation === 180) return 0
    return rotation
  }

  public pieChartOptions: ChartOptions = {
    responsive: true,
  }
  public pieChartLabels: Label[] = ['Achievement', 'Ingenuity', 'Independence', 'Resilience', 'Risk-taking', 'Self-Efficacy', 'Social', 'Intelligence']
  public pieChartData: SingleDataSet = [1, 1, 1, 1, 1, 1, 1, 1]
  public pieChartType: ChartType = 'pie'
  public pieChartLegend = true
  public pieChartPlugins = []

  constructor(
    private profilesService: profilesService,
    public route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private firebaseAuthService: firebaseAuthService,
  ) {
    monkeyPatchChartJsTooltip()
    monkeyPatchChartJsLegend()
  }

  ngOnInit() {
    this.profileDetails = []
    this.profileId = this.route.snapshot.paramMap.get('profileId')
    this.profilesService.getProfile(this.profileId).then(profileDetails => {
      this.profile = profileDetails

      const urlFriendlyProfileId = this.profile.id.toString().replace(/\s/g, '%20')

      this.profileDetails = [
        { label: 'Title', description: this.profile.title },
        { label: 'Name', description: this.profile.name },
        { label: 'Email', description: this.profile.email },
        { label: 'Gender', description: this.profile.gender },
        { label: 'Location', description: this.profile.location },
        { label: 'Personal Description', description: this.profile.personalDescription },
        { label: 'Company', description: this.profile.companyName },
        { label: 'Created', description: this.convertDate(this.profile.created) },
        { label: 'Completed', description: this.profile.allQuestionsAnswered ? 'Yes' : 'No' },
        { label: 'Url', description: `https://assessment.matchie.io/questions/${urlFriendlyProfileId}` },
      ]
      if (this.profile && this.profile.generateReport) {
        this.requestedReport = true
      }
      if (this.profile && this.profile.generateFounderReport) {
        this.requestedFounderReport = true
      }

      if (this.profile && this.profile.reportLink && this.profile.reportLink.length > 0) {
        this.reportLinkPopulated = true
      }

      this.displayDownloadReportButtonInvestor = this.profile.reportStatus === 'DONE' ? true : false
      this.displayDownloadReportButtonFounder = this.profile.reportStatusFounder === 'DONE' ? true : false

      this.allQsAnswered = this.profile.allQuestionsAnswered
      this.profilesService.getProfileAnswers(this.profileId).then((profileAnswers: any) => {
        this.profileAnswers = profileAnswers.sort((a, b) => (a.questionNumber > b.questionNumber ? 1 : -1))
        this.profileAnswers = profileAnswers
        this.pieDatabuild(profileAnswers)
        let categoryCounters = {}
        let subCategoryCounters = {}
        let words = ''
        this.profileAnswers.forEach(element => {
          const createdDate = element.created
          element.category in categoryCounters ? categoryCounters[element.category]++ : (categoryCounters[element.category] = 1)
          element.subcategory in subCategoryCounters ? subCategoryCounters[element.subcategory]++ : (subCategoryCounters[element.subcategory] = 1)
          words += element.subcategory + ' '
        })

        this.wordCloudData = Object.entries(subCategoryCounters).map(([text, value]) => {
          return { text, value } as { text: string; value: number }
        })
      })
    })
    this.profilesService.getProfileNotes(this.profileId).then(profileNotes => {
      this.notes = profileNotes
      this.notes = this.notes.filter(note => note.createdByName !== 'Duncan Corbett')
    })
  }

  pieDatabuild(profileAnswers) {
    let pieData = []

    let categories = profileAnswers.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index)

    for (const category of categories) {
      pieData.push({ heading: category, count: 0 })
    }

    for (const answer of this.profileAnswers) {
      for (let item of pieData) {
        if (item.heading == answer.category) {
          item.count++
        }
      }
    }
    this.setPieData(pieData, categories)
  }

  setPieData(pieData, categories) {
    this.pieChartLabels = categories

    this.pieChartData = []
    for (const item of pieData) {
      this.pieChartData.push(item.count)
    }
  }

  openModal(content: any) {
    this.modalService.open(content, { centered: true })
  }

  public async deleteProfile(profileId: string): Promise<void> {
    await this.profilesService.deleteProfile(profileId)
    this.router.navigateByUrl('/questionaires')
  }
  public convertDate(item) {
    let utcSeconds = item.seconds
    let d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)
    return this.addZeroDigit(d.getDate()) + '/' + this.addZeroDigit(d.getMonth() + 1) + '/' + d.getFullYear()
  }

  addZeroDigit(item) {
    item = item.toString()
    return item.length == 1 ? '0' + item : item
  }

  async downloadProfile() {
    const csvData = await this.profilesService.getIndividualAnswers(this.profileId)
    this.saveBlob(csvData, this.profileId + '_answers.csv')
  }

  requestReport() {
    this.requestedReport = true
    return this.firebaseAuthService.requestReport(this.profileId)
  }

  async downloadReport() {
    await this.firebaseAuthService.downloadReport(this.profileId)
  }

  requestFounderReport() {
    this.requestedFounderReport = true
    return this.firebaseAuthService.requestFounderReport(this.profileId)
  }

  async downloadFounderReport() {
    await this.firebaseAuthService.downloadFounderReport(this.profileId)
  }

  async regenerateReports() {
    await this.firebaseAuthService.regenerateReports(this.profileId)
  }

  private saveBlob(csvData: any, name: string) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, name) // FIXME: This is depricated https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveBlob
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', name)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}
