<div class="table-responsive text-nowrap">
  <nz-table #basicTable [nzData]="tableData" [nzShowPagination]="true" class="table mb-4">
    <thead>
      <tr>
        <th class="bg-transparent text-uppercase text-left font-weight-bold">#</th>
        <th class="bg-transparent text-uppercase text-left font-weight-bold">Question</th>
        <th class="bg-transparent text-uppercase text-right font-weight-bold">Category</th>
        <th class="bg-transparent text-uppercase text-right font-weight-bold">Answer</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td class="font-weight-bold text-left width-50">{{data.questionNumber}}</td>
        <td>
          <div class="text-left">
            <div>{{data.question}}</div>
          </div>
        </td>
        <td class="text-right">{{data.category}} - {{data.subcategory}}</td>
        <td class="font-weight-bold text-right text-wrap">{{data.answer}}</td>
      </tr>
    </tbody>
  </nz-table>
</div>