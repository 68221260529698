export const environment = {
  production: false,
  authenticated: false,
  hmr: false,
}

export const firebaseConfig = {
  apiKey: "AIzaSyD3DkVSEpff6jXZm7TGZWOaF1I74BDBcxY",
  authDomain: "matchie-dev.firebaseapp.com",
  projectId: "matchie-dev",
  storageBucket: "matchie-dev.appspot.com",
  messagingSenderId: "644685545803",
  appId: "1:644685545803:web:945f666529fb333083a8ce"
};