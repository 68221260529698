<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="filter--dropdown">
          <ng-container>
            <button nz-button nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'bottomLeft'">Version</button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li (click)="filterProfiles('all')" nz-menu-item>All</li>
                <li (click)="filterProfiles(1)" nz-menu-item>1</li>
                <li (click)="filterProfiles(2)" nz-menu-item>2</li>
              </ul>
            </nz-dropdown-menu>
          </ng-container>
        </div>
        <div class="card-body"><nldr-widgets-tables-3 [tableData]="profiles" (exportReport)="exportProfileCSV($event)" (exportAnswersReport)="exportAnswersCSV($event)"></nldr-widgets-tables-3></div>
      </div>
    </div>
  </div>
</div>
