import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'nldr-typography-3',
  templateUrl: './3.component.html',
  styleUrls: ['./3.component.scss'],
})
export class NLDORTypography3Component implements OnInit {
  @Input() items: [] = []
  constructor() {}
  ngOnInit() {
    console.log("items : ", this.items);
  }
}
