import { profilesService } from './../../services/firebase/profiles.service'
import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-questionaires',
  templateUrl: './questionaires.component.html',
  styleUrls: ['./questionaires.component.scss'],
})
export class QuestionairesComponent implements OnInit {
  profiles: any
  subscription: Subscription
  allProfiles = []

  constructor(private profilesService: profilesService) {}
  ngOnInit() {
    this.subscription = this.profilesService.getProfiles().subscribe(profilesList => {
      this.allProfiles = profilesList
      this.profiles = profilesList
    })
  }

  filterProfiles(key) {
    if (key == 'all') {
      return (this.profiles = this.allProfiles)
    }
    this.profiles = this.allProfiles
    this.profiles = this.profiles.filter(item => item.version == key)
  }

  private saveBlob(csvData: any, name: string) {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, name) // FIXME: This is depricated https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveBlob
    } else {
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', name)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  public async exportProfileCSV(event) {
    const csvData = await this.profilesService.getProfileExport()
    this.saveBlob(csvData, 'exportMatchie.csv')
  }

  public async exportAnswersCSV(event) {
    const csvData = await this.profilesService.getAnswersExport()
    this.saveBlob(csvData, 'exportMatchieAnswers.csv')
  }
}
