<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-6">
              <vb-headers-card-header [data]="{ title: 'Profile details' }"></vb-headers-card-header>
            </div>
            <div class="col-6">
              <div class="float-right">
                <a (click)="downloadProfile()" type="button" style="width: 11em; margin-bottom: 2px" class="btn btn-outline-primary mr-2 float-right">
                  <i class="fa fa-file-excel-o"></i>
                  Download Profile
                </a>
                <a
                  (click)="downloadReport()"
                  *ngIf="displayDownloadReportButtonInvestor"
                  type="button"
                  style="width: 11em; margin-bottom: 2px"
                  class="btn btn-outline-primary mr-2 float-right"
                >
                  <i class="fa fa-file-word-o"></i>
                  Investor Report
                </a>
                <a
                  (click)="downloadFounderReport()"
                  *ngIf="displayDownloadReportButtonFounder"
                  type="button"
                  style="width: 11em; margin-bottom: 2px"
                  class="btn btn-outline-primary mr-2 float-right"
                >
                  <i class="fa fa-file-word-o"></i>
                  Founder Report
                </a>
                <a
                  (click)="regenerateReports()"
                  *ngIf="displayDownloadReportButtonInvestor || displayDownloadReportButtonFounder"
                  type="button"
                  style="width: 11em; margin-bottom: 2px"
                  class="btn btn-outline-primary mr-2 float-right"
                >
                  <i class="fa fa-refresh"></i>
                  Re-generate Reports
                </a>
                <a (click)="openModal(vertical)" type="button" style="width: 11em; margin-bottom: 2px" class="btn btn-danger mr-2 float-right">
                  <i class="fe fe-trash-2"></i>
                  Delete Profile
                </a>
              </div>

              <ng-template #vertical let-modal>
                <div class="modal-header">
                  <h5 class="modal-title" id="modal-basic-title">Are you sure?</h5>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  This will completely and irreversibility delete this profile.
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" (click)="modal.close()">
                    Cancel
                  </button>
                  <button type="button" class="btn btn-primary" (click)="modal.close(); deleteProfile(profileId)">
                    Yes, Delete Profile
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="card-body">
          <nldr-typography-3 [items]="profileDetails"></nldr-typography-3>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <vb-headers-card-header [data]="{ title: 'Questions' }"></vb-headers-card-header>
            </div>
          </div>
        </div>
        <div class="card-body">
          <nldr-widgets-tables-2 [tableData]="profileAnswers"></nldr-widgets-tables-2>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <vb-headers-card-header [data]="{ title: 'Primary Constructs' }"></vb-headers-card-header>
            </div>
          </div>
        </div>
        <div style="display: block;">
          <canvas
            baseChart
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [plugins]="pieChartPlugins"
            [legend]="pieChartLegend"
          >
          </canvas>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <vb-headers-card-header [data]="{ title: 'Notes' }"></vb-headers-card-header>
            </div>
          </div>
        </div>
        <div class="card-body">
          <nldor-forms-2 [profileId]="profileId"></nldor-forms-2>
          <nldr-widgets-lists-4 [items]="notes"></nldr-widgets-lists-4>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-9">
              <vb-headers-card-header [data]="{ title: 'Word Cloud' }"></vb-headers-card-header>
            </div>
          </div>
        </div>
        <div class="card-body" #wordCloudContainer>
          <angular-d3-cloud font="impact" [data]="wordCloudData" [rotate]="wordCloudRotate" [fontSizeMapper]="wordCloudFontSizeMapper" [autoFill]="true" [animations]="true">
          </angular-d3-cloud>
        </div>
      </div>
    </div>
  </div>
</div>
