import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'nldr-widgets-general-24v1',
  templateUrl: './24v1.component.html',
})
export class NLDRGeneral24v1Component implements OnInit {
  @Input() count:any = 0;
  @Input() label:any = "Label";
  constructor() {}
  ngOnInit() {}
}
