import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'nldr-widgets-lists-4',
  templateUrl: './4.component.html',
  styleUrls: ['./4.component.scss'],
})
export class NLDRList4Component implements OnInit {
  @Input() items: [] = []
  constructor() {}
  ngOnInit() {}
}
