<div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-body bg-primary text-white rounded">
          <nldr-widgets-general-24 [count]="profilesCount" [label]="profilesLabel"></nldr-widgets-general-24>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="card">
        <div class="card-body bg-light text-dark rounded">
          <nldr-widgets-general-24v1 [count]="completedProfilesCount" [label]="completedProfilesLabel"></nldr-widgets-general-24v1>
        </div>
      </div>
    </div>
  </div>
</div>
