import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'

@Injectable({
  providedIn: 'root',
})
export class usersService {

  constructor(
    public firebaseDatabase: AngularFirestore,
  ) {

  }

  getUsers() {
    return this.firebaseDatabase.collection('users').valueChanges({ idField: 'id' });
  }
}
