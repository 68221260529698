import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
declare var require: any
//const data: any = require('./data.json')

@Component({
  selector: 'nldr-widgets-tables-3',
  templateUrl: './3.component.html',
  styleUrls: ['./3.component.scss'],
})
export class NLDRTable3Component implements OnInit {
  @Input() tableData: [] = []
  @Output() exportReport = new EventEmitter<boolean>()
  @Output() exportAnswersReport = new EventEmitter<boolean>()
  constructor() {}
  ngOnInit() {}

  public exportProfileCSVEvent() {
    this.exportReport.emit(true)
  }

  public exportAnswersCSVEvent() {
    this.exportAnswersReport.emit(true)
  }

  public convertDate(item) {
    let utcSeconds = item.seconds
    let d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)
    return this.addZeroDigit(d.getDate()) + '/' + this.addZeroDigit(d.getMonth() + 1) + '/' + d.getFullYear()
  }

  public convertTime(item) {
    let utcSeconds = item.seconds
    let d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)
    return this.addZeroDigit(d.getHours()) + ':' + this.addZeroDigit(d.getMinutes())
  }

  addZeroDigit(item) {
    item = item.toString()
    return item.length == 1 ? '0' + item : item
  }
}
