export const getMenuData: any[] = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    icon: 'fe fe-home',
  },
  {
    title: 'Questionaires',
    key: '163ecv',
    url: '/questionaires',
    icon: 'fe fe-file-text',
  }
  // VB:REPLACE-END:MENU-CONFIG
]
