import { Injectable } from '@angular/core'
import { noldorUtils } from './utils'

@Injectable({
  providedIn: 'root',
})
export class profilesService {
  constructor(public noldorUtils: noldorUtils) {}

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  answerValueMapping = {
    'Strongly agree': 5,
    Agree: 4,
    'Not sure': 3,
    Disagree: 2,
    'Strongly disagree': 1,
  }

  ECategories = {
    Achievement: 0,
    Independence: 1,
    Ingenuity: 2,
    Resilience: 3,
    Risktaking: 4,
    'Self-efficacy': 5,
    'Social Intelligence': 6,
  }

  ESubCategories = {
    Action: 0,
    Ambition: 1,
    Appetite: 2,
    Assertive: 3,
    Autonomy: 4,
    Confidence: 5,
    Empathy: 6,
    Flexibility: 7,
    Innovation: 8,
    Learning: 9,
    'Locus of Control': 10,
    Persistence: 11,
    Recovery: 12,
    'Relationship building': 13,
    'Relationship leveraging': 14,
    Structure: 15,
    'Task focused': 16,
    'Work ethic': 17,
  }

  excludeQuestions = ['CC_1', 'CC_2', 'CC_3', 'CC_4', 'Ea_Ql_1']

  getProfiles() {
    return this.noldorUtils.subscribeToCollectionList('profile')
  }

  getProfile(profileId) {
    return this.noldorUtils.getDocument(`profile/${profileId}`)
  }

  getProfilesCount() {
    return this.noldorUtils.subscribeToCollectionList('profile')
  }

  getProfileAnswers(profileId) {
    return this.noldorUtils.getCollectionList(`profile/${profileId}/answers`)
  }

  getProfileNotes(profileId) {
    return this.noldorUtils.getCollectionList(`profile/${profileId}/notes`)
  }

  addProfileNote(profileId, note, currentUser) {
    let noteDetails = {
      created: new Date(),
      createdById: currentUser.id,
      createdByName: currentUser.displayName,
      createdByImage: currentUser.avatar ? currentUser.avatar : '/favicon.png',
      note: note,
    }

    return this.noldorUtils.addDocumentToCollection(`profile/${profileId}/notes`, noteDetails)
  }

  async getProfileExport() {
    const negativeImpacts = {
      5: 1,
      4: 2,
      3: 3,
      2: 4,
      1: 5,
    }
    let csvData = `ProfileId, Company Name, Name, Completed, Created, Version,`
    await this.noldorUtils.getCollectionList('questions').then(async (questions: any) => {
      questions = questions.sort((a, b) => (a.questionNumber > b.questionNumber ? 1 : -1))
      await this.asyncForEach(questions, questionData => {
        if (!this.excludeQuestions.includes(questionData.id)) {
          csvData += [questionData.questionNumber] + '(' + [questionData.id] + '),'
        }
      })
    })
    csvData += '\n'
    return await this.noldorUtils.getFilteredCollectionList('profile', 'version', 2).then(async (profiles: any) => {
      await this.asyncForEach(profiles, async profileData => {
        const completed = profileData.allQuestionsAnswered ? 'Yes' : 'No'
        const createdDate = new Date(profileData.created.seconds * 1000).toISOString().split('T')[0]
        csvData += `"${profileData.id}","${profileData.companyName}","${profileData.name}","${completed}","${createdDate}","${profileData.version}",`
        let profileAnswers: any = await this.noldorUtils.getCollectionList(`/profile/${profileData.id}/answers`)
        profileAnswers = profileAnswers.sort((a, b) => (a.questionNumber > b.questionNumber ? 1 : -1))
        await this.asyncForEach(profileAnswers, async answerData => {
          let answer = ''
          if (!answerData.answer) {
            answer = ''
          } else if (this.answerValueMapping[answerData.answer]) {
            answer = this.answerValueMapping[answerData.answer]
          } else {
            answer = answerData.answer
            answer = answer.replace(/(?:\r\n|\r|\n)/g, '')
            answer = answer.replace(/\'/g, '')
          }
          //console.log('Not a number', answerData['impact'], answer, answerData.id)
          if (answerData['impact'] && typeof answer === 'number') {
            if (answerData['impact'] === 'negative') {
              answer = negativeImpacts[answer]
            }
          }

          if (!this.excludeQuestions.includes(answerData.id)) {
            csvData += answer + ','
          }
        })
        csvData += '\n'
      })
      return Promise.resolve(csvData)
    })
  }

  async getAnswersExport(): Promise<any[]> {
    const profileDocs = (await this.noldorUtils.getCollectionList('profile')) as any[]

    const csvData = await Promise.all(
      profileDocs.map(async ({ id, name, allQuestionsAnswered }) => {
        const profileAnswers = (await this.noldorUtils.getCollectionList(`profile/${id}/answers`)) as any[]

        return await Promise.all(
          profileAnswers.map(({ questionNumber, question, answer }) => {
            answer = answer.replace(/(?:\r\n|\r|\n)/g, '')
            answer = answer.replace(/\'/g, '')
            return `\n"${id}","${name}","${allQuestionsAnswered}","${questionNumber}","${question}","${answer}"`
          }),
        )
      }),
    )

    const reducedCsvData = csvData.reduce((arr0, arr1) => arr0.concat(arr1))

    reducedCsvData.unshift('"ID","NAME","COMPLETED","questionNumber","question","answer"')

    return reducedCsvData
  }

  async getIndividualAnswers(profileId) {
    const profileAnswers = (await this.noldorUtils.getCollectionList(`profile/${profileId}/answers`)) as any[]
    const csvData = profileAnswers.map(({ answered, questionNumber, question, answer, category, subcategory }) => {
      answer = answer.replace(/(?:\r\n|\r|\n)/g, '')
      answer = answer.replace(/\'/g, '')
      let categoryValue = this.ECategories[category]
      let subcategoryValue = this.ESubCategories[subcategory]
      let score = this.answerValueMapping[answer]
      return `\n"${profileId}","${answered}","${questionNumber}","${question}","${answer}","${category}","${categoryValue}","${subcategory}","${subcategoryValue}","${score}"`
    })
    csvData.unshift('"ID","answered","questionNumber","question","answer","Construct Name","value","Sub construct","value","score"')
    const reducedCsvData = csvData.reduce((arr0, arr1) => arr0.concat(arr1))
    return reducedCsvData
  }

  public deleteProfile(profileId: string): Promise<void> {
    return this.noldorUtils.deleteDocument(`profile/${profileId}`)
  }
}
